import * as React from "react"
import Layout from "../components/layout";
import { iframeview } from "../components/layout.module.css"

const IndexPage = () =>
{
  return (
      <Layout>
        <iframe title="Main App View" className={iframeview} src="/AppView"></iframe>
      </Layout>
  );
};

export default IndexPage;
export const Head = () => <title>Home Page</title>;
